import {createRouter, createWebHashHistory} from 'vue-router'

//路由规则
const routes = [
    {
        path: '/',
        name: 'HelloWorld',
        component: () => import('../components/HelloWorld')
    },
    {
        path: '/index',
        name: 'index',
        component: () => import('../views/IndexPage')
    },
    {
        path: '/users/i/:id',   //路由带参数
        name: 'userMsg',
        component: () => import('../views/UserMsg')
    }
]

//根据路由规则创建路由
const router = createRouter({
    history: createWebHashHistory(""),
    routes
});
export default router;