import {createApp} from 'vue'
import App from './App.vue'

//路由router
import router from './router'

// Element Plus
import ElementPlus from 'element-plus'
import 'element-plus/dist/index.css'

// createApp(App).use(router).mount('#app')

const app = createApp(App)
app.use(router)     //路由router
app.use(ElementPlus)    // Element Plus
app.mount('#app')

